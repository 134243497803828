import React, { useEffect } from 'react';
import './SeeMore.scss';
import gsap from 'gsap';
import clsx from 'clsx';

const SeeMore = React.forwardRef(({ url, text, id, afterArrow }, ref) => {
    useEffect(() => {
        const time = 50;
        function startRolling() {
            const rolling = document.getElementById(id);
            const __text = rolling.getElementsByClassName('see-more__slider__item__text')[0];
            const width = __text.clientWidth;
            __text.style.marginLeft = `${-width}px`;
            gsap.fromTo(rolling, { x: 0 }, {
                x: `+=${width}`,
                ease: 'none',
                repeat: -1,
                duration: width / time,
            });
        }
        if (document.readyState === 'complete') {
            startRolling();
        } else {
            window.addEventListener('load', startRolling);
        }
    }, [id]);
    return (
        <a className="a-black" href={url}>
            <div className="see-more">
                <div ref={ref} className="see-more__slider" id={id}>
                    { [...Array(10).keys()].map((item) => (
                        <div
                            className={clsx(afterArrow && 'after-arrow',
                                ' see-more__slider__item see-more__slider__item__text ')}
                            key={item}
                        >
                            {text}
                        </div>
                    ))}
                </div>
            </div>
        </a>
    );
});

export default SeeMore;
