import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useRef } from 'react';
import { Helmet } from 'react-helmet';
import Footer from '../sections/Footer/Footer';
import Navbar from '../components/Navbar/Navbar';
import SeeMore from '../components/SeeMore/SeeMore';
import ScrollTop from '../components/ScrollTop/ScrollTop';
import entryAnimation from '../resources/entry-animation';

const People = ({ people }) => (
    <div className="wrapper">
        {people
        && people.map((person) => (
            <div className="row-wrapper" key={person.name}>
                <div className="contact__people-section__names">
                    <p className="contact__people-section__text">{`${person.position}:`}</p>
                    <p className="contact__people-section__text yellow-text">
                        {`${person.name || ''} ${person.surname || ''}`}
                    </p>
                </div>
                <div className="contact__people-section__addresses">
                    <a
                        href={`tel:${person.phoneNumber}`}
                        className="contact__people-section__text btn btn--underlined"
                    >
                        {person.phoneNumber}
                    </a>
                    <a
                        href={`mailto:${person.eMail}`}
                        className="contact__people-section__text btn btn--underlined"
                    >
                        {person.eMail}
                    </a>
                </div>
            </div>
        ))}
    </div>
);

const Addresses = ({ addresses }) => (
    <div className="wrapper">
        {addresses
        && addresses.map((address) => (
            <div className="row-wrapper" key={address.name}>
                <div className="contact__company-section__title">
                    <h4>{address.name}</h4>
                </div>
                <div className="contact__company-section__address">
                    {`${address.street}\n`}
                    {`${address.zipcode} ${address.city}`}
                </div>
                <div className="contact__company-section__info">
                    {`KRS: ${address.krs}\n`}
                    {`NIP: ${address.nip}`}
                </div>
            </div>
        ))}
    </div>
);

const Contact = () => {
    const data = useStaticQuery(query);
    const navbarBreakpointRef = useRef();
    const mainRef = useRef(null);
    entryAnimation(mainRef, true);
    return (
        <main ref={mainRef} className="contact">
            <div ref={navbarBreakpointRef} className="ref-navbar" />
            <Navbar
                breakpointRef={navbarBreakpointRef}
            />
            <Helmet>
                <title>KONTAKT | GetHero</title>
            </Helmet>
            <div className="contact__title-section">
                <div className="contact__title-section__title">
                    <h1>#CO MOŻEMY DLA CIEBIE ZROBIĆ?</h1>
                </div>
            </div>
            <div className="contact__people-section">
                <People people={data.allContact.nodes[0].people} />
            </div>
            <div className="contact__spacer" />

            <div className="contact__company-section">
                <Addresses addresses={data.allContact.nodes[0].companyInformation} />
            </div>
            <SeeMore text={data.allContact.nodes[0].slider} id="see-more-kontakt" />
            <Footer
                showOnlyBasic
            />
            <ScrollTop />
        </main>
    );
};

const query = graphql`
  query Contact {
    allContact {
      nodes {
        language 
        people {
          eMail
          name
          phoneNumber
          position
          surname
        }
        slider
        companyInformation {
          krs
          name
          nip
          street
          zipcode
          city
        }
      }
    }
  }
`;

export default Contact;
